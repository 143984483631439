// TODO: add cross linking with the router paths constant
export const FOOTER_LINKS = [
  {
    title: 'PLATFORM',
    links: [
      {
        name: 'About',
        path: '/about'
      },
      {
        name: 'Help Center',
        path: '/onboarding'
      },
      {
        name: 'FAQ',
        path: '/help'
      }
    ]
  },
  {
    title: 'COMMUNITY',
    isExternal: true,
    links: [
      {
        name: 'X (Twitter)',
        path: 'https://x.com/xyro_io'
      },
      {
        name: 'Discord',
        path: 'https://discord.gg/xyro'
      },
      {
        name: 'Telegram',
        path: 'https://t.me/xyro_io'
      },
      {
        name: 'Mirror',
        path: 'https://mirror.xyz/xyro.eth'
      }
      // {
      //   name: 'Instagram',
      //   path: ''
      // },
      // TODO: temporal hiding; return when the legal pages are ready
      // {
      //   title: 'LEGAL',
      //   links: [
      //     {
      //       name: 'Privacy Policy',
      //       path: '/privacy-policy'
      //     },
      //     {
      //       name: 'AML Policy',
      //       path: '/aml-policy'
      //     },
      //     {
      //       name: 'Terms and conditions',
      //       path: '/terms-and-conditions'
      //     }
      //   ]
      // },
    ]
  }
]

export const SERVICE_DESCRIPTIONS = [
  'XYRO is an AI-powered gamified trading platform that leverages gamification and social features to redefine crypto, making it accessible and engaging.'
]

export const CHAT_SIDEBAR_BUTTON_ID = 'chat_sidebar_button_id'
