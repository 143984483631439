import { Head } from 'features/head'
import { OnboardingUpDown } from 'features/onboarding'

export const OnboradingModeUpDownPage: React.FC = () => {
  return (
    <>
      <Head title='Onborading' />
      <OnboardingUpDown />
    </>
  )
}
