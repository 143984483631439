import { Head } from 'features/head'
import { OnboardingBullsEye } from 'features/onboarding'

export const OnboradingModeBullsEyePage: React.FC = () => {
  return (
    <>
      <Head title='Onborading' />
      <OnboardingBullsEye />
    </>
  )
}
