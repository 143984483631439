/* eslint-disable max-lines */
export enum DataTestIDs {
  // NOTE: Naming structure for common cases
  // [element][place(position)][action?]

  buttonLoginHeader = 'btnLoginHeader',
  buttonConnectWalletHeader = 'btnConnectWalletHeader',
  balanceValueHeader = 'balanceValueHeader',
  buttonRewardsHeader = 'headerRewards',
  buttonApprovedBalanceHeader = 'headerApprovedBalance',
  buttonGlobalChatHeader = 'headerOpenGlobalChat',
  buttonProfileHeader = 'headerProfileAvatar',
  buttonGlobalChatClose = 'btnHideGlobalChat',
  buttonGlobalChatSendMessage = 'sendMsgToGlobalChat',
  buttonProfileLink = 'btnProfile',
  buttonSettingsLink = 'btnSettings',
  buttonLogOut = 'btnLogOut',
  profileHeaderContent = 'profileHeaderContent',
  buttonConnectViaMM = 'connectViaMM',
  connectWalletModal = 'connectWalletModal',
  signUpModal = 'signUpModal',
  buttonUseRefferalCode = 'btnUseRefCode',
  refferalCodeInput = 'refCodeInput',
  buttonContinue = 'btnContinue',
  upDownSidePanel = 'upDownSidePanel',
  bullsEyeSidePanel = 'bullsEyeSidePanel',
  oneVsOneSidePanel = 'oneVsOneSidePanel',
  setupsSidePanel = 'setupsSidePanel',
  rewardsSidePanel = 'rewardsSidePanel',
  referralsSidePanel = 'referralsSidePanel',
  upDownGameStatus = 'upDownGameStatus',
  upDownAssetPriceSP = 'upDownAssetPriceSP',
  upDownAssetPriceFP = 'upDownAssetPriceFP',
  buttonBetUpUpDown = 'upDownBetUp',
  buttonBetDownUpDown = 'upDownBetDown',
  resultUpDownUpWins = 'resultUpWins',
  resultUpDownDownWins = 'resultDownWins',
  createNameInput = 'createNameInput',
  buttonSaveCreatedName = 'btnSaveCreatedName',
  buttonHeaderGetStarted = 'btnGetStarted',
  buttonHeaderConnectWallet = 'btnConnectWallet',
  buttonUpDownConnectWallet = 'btnUpDownConnectWallet',
  buttonUpDownSignInToPlay = 'btnUpDownSignInToPlay',
  buttonUpDownSwitchChain = 'btnUpDownSwitchChain',
  bullsEyeGraphHeaderStatus = 'bullsEyeGameStatus',
  bullsEyeGraphAssetPrice = 'bullsEyeAssetPrice',
  inputBullsEyePredict = 'bullsEyePredictInput',
  buttonBullsEyeMakePredict = 'bullsEyePlaceBid',
  bullsEyeWinnerItem = 'bullsEyeWinner',
  validationMessageAmountMismatch = 'validationMessageAmountMismatch',
  validationMessageTypeMismatch = 'validationMessageTypeMismatch',
  validationMessageAmountLarge = 'validationMessageAmountLarge',
  validationMessageAmountSmall = 'validationMessageAmountSmall',
  validationMessageBadInput = 'validationMessageBadInput',
  validationMessageInsufficientBalance = 'validationMessageInsufficientBalance',
  buttonBullsEyeConnectWallet = 'btnBullsEyeConnectWallet',
  buttonBullsEyeSignInToPlay = 'btnBullsEyeSignInToPlay',
  buttonBullsEyeSwitchChain = 'btnBullsEyeSwitchChain',
  buttonOneVsOneSelectAsset = 'oneVsOneSelectAsset',
  buttonOneVsOneSelectAssetTrigger = 'oneVsOneAssetDropDown',
  inputOneVsOnePredictPrice = 'oneVsOnePredictPriceInput',
  inputOneVsOnePredictAmount = 'oneVsOneBidPriceInput',
  buttonOneVsOnePlay = 'oneVsOneBtnPlay',
  buttonOneVsOneConnectWallet = 'buttonOneVsOneConnectWallet',
  buttonOneVsOneSignInToPlay = 'buttonOneVsOneSignInToPlay',
  buttonOneVsOneSwitchChain = 'buttonOneVsOneSwitchChain',
  oneVsOneSelectedAssetName = 'oneVsOneAssetName',
  oneVsOneSelectedAssetPrice = 'oneVsOneAssetPrice',
  buttonOneVsOneSwitchToGlobal = 'oneVsOneBtnSwitchToGlobal',
  buttonOneVsOneSwitchToPersonal = 'oneVsOneBtnSwitchToPersonal',
  buttonOneVsOneSwitchToCurrent = 'oneVsOneBtnSwitchToCurrent',
  buttonOneVsOneSwitchToCompleted = 'oneVsOneBtnSwitchToCompleted',
  buttonOneVsOneSwitchToExpired = 'oneVsOneBtnSwitchToExpired',
  buttonOneVsOneAccept = 'oneVsOnebtnAccept',
  buttonOneVsOneDeny = 'oneVsOnebtnDeny',
  buttonOneVsOneOpenDetails = 'oneVsOneOpenDetails',
  oneVsOneUserAcceptBid = 'oneVsOneUserAcceptBid',
  buttonOneVsOneAcceptGame = 'oneVsOneAcceptGame',
  inputOneVsOneAcceptModalPredictPrice = 'oneVsOneAcceptModalPredictInput',
  buttonOneVsOneConfirmGame = 'oneVsOneConfirmGame',
  buttonOneVsOneRejectGame = 'oneVsOneRejectGame',
  buttonCreateSetup = 'setupsBtnCreateSetup',
  cardOpenedSetup = 'setupsOpenedSetup',
  cardOpenedSetupOwner = 'setupsOpenedSetupCreator',
  buttonSelectOpenedSetup = 'setupsSelectOpenedSetup',
  buttonSetupsSwitchToCurrent = 'setupsBtnSwitchToCurrent',
  buttonSetupsSwitchToCompleted = 'setupsBtnSwitchToCompleted',
  buttonSetupsSwitchToActive = 'setupsBtnSwitchToActive',
  buttonSetupsSwitchToClosed = 'setupsBtnSwitchToClosed',
  setupsSelectedAssetName = 'setupsAssetName',
  setupsSelectedAssetPrice = 'setupsAssetPrice',
  buttonSetupsLong = 'setupsBtnLong',
  buttonSetupsShort = 'setupsBtnShort',
  dropDownSetupsSelectAsset = 'setupsSelectAssetDropDown',
  buttonSetupsSelectAsset = 'setupsSelectAsset',
  buttonsSetupsTimeframe = 'setupsTimeFrame',
  buttonSetupsTimeframe = 'setupsTimeFrameBtn',
  inputSetupsTP = 'setupsInputTP',
  inputSetupsSL = 'setupsInputSL',
  setupsBidAmountSelectors = 'setupsJoinSetupsBidAmountSelector',
  inputSetupsBidAmount = 'setupsJoinSetupBidAmountInput',
  buttonSetupsJoinSetup = 'setupsJoinSetupBtnPlay',
  buttonJoinSetupsTP = 'setupsJoinSetupTPBtn',
  buttonJoinSetupsSL = 'setupsJoinSetupSLBtn',
  dropdownOneVsOneSelectOpponent = 'oneVsOneBtnSelectOpponent',
  inputOneVsOneFindUser = 'oneVsOnePrivateGameUserInput',
  dropdownItemOneVsOneFindUser = 'oneVsOnePrivateGameUser',
  tableUserGamesUpDown = 'tableUserGamesUpDown',
  tableUserGamesBullsEye = 'tableUserGamesBullsEye',
  tableOneVsOneOpenGames = 'tableOneVsOneOpenGames',
  tableOneVsOneUserGames = 'tableOneVsOneUserGames',
  tableSetupsJoinedSetups = 'tableSetupsJoinedSetups',
  tableSetupsCreatedSetups = 'tableSetupsCreatedSetups',
  buttonSignInWithWalletHeader = 'buttonSignInWithWalletHeader',
  buttonSetupsOpenDetails = 'setupsOpenDetails',
  btnJoinedSetupsAccept = 'btnJoinedSetupsAccept',
  buttonSetupsSwitchToUnclaimed = 'buttonSetupsSwitchToUnclaimed',
  headerRewardAmount = 'rewardAmountHeader',
  buttonRewardsClaimReward = 'claimRewardBtn',
  userSettingsAvatar = 'userSettingsAvatar',
  userSettingsName = 'userSettingsName',
  buttonUserSettingsChangeName = 'userSettingsChangeNameBtn',
  userSettingsBio = 'userSettingsBio',
  buttonUserSettingsChangeBio = 'userSettingsChangeBioBtn',
  userSettingsUploadAvatarArea = 'userSettingsUploadAvatarArea',
  buttonUserSettingsUploadAvatar = 'userSettingsUploadAvatarBtn',
  userSettingsChangeNameInput = 'userSettingsChangeNameInput',
  buttonUserSettingsChangeNameSave = 'userSettingsChangeNameSaveBtn',
  userSettingsChangeBioInput = 'userSettingsChangeBioInput',
  buttonUserSettingsChangeBioSave = 'userSettingsChangeBioSaveBtn',
  treasureTotalValue = 'treasureTotalValue',
  treasureWalletValue = 'treasureWalletValue',
  treasureApprovedValue = 'treasureApprovedValue',
  treasureTreasureValue = 'treasureTreasureValue',
  buttonTreasureApproveBalance = 'btnApprovedBalance',
  buttonTreasureClaim = 'btnClaimTreasure',
  treasureAvailableAmount = 'treasureAvailableAmount',
  treasureInput = 'treasureInput',
  buttonTreasureMax = 'treasureMaxBtn',
  buttonTreasureAction = 'treasureActionBtn',
  bullsEyeYouSayValue = 'bullsEyeYouSayValue',
  buybackSidePanel = 'buybackSidePanel',
  bullsEyeWMPrize = 'bullsEyeWMPrize',
  bullsEyeWinnerModalWon = 'bullsEyeWinnerModalWon',
  bullsEyeWinnerModalLost = 'bullsEyeWinnerModalLost',
  chatInput = 'chatInput',
  chatUsersMessage = 'usersMessage',
  chatButtonSwitchToGameChat = 'btnSwitchToGameChat',
  chatButtonSwitchToGlobalChat = 'btnSwitchToGlobalChat',
  chatBlock = 'chat',
  upDownResultIconLost = 'upDownResultIconLost',
  upDownResultIconWon = 'upDownResultIconWon',
  rewardsTaskContainer = 'rewardsTaskContainer',
  userSettingsDeleteAvatar = 'userSettingsDeleteAvatar',
  buttonNavBarGames = 'navBarGamesBtn',
  buttonNavBarMore = 'navBarMoreBtn',
  buttonNavBarHome = 'navBarHomeBtn'
}
