import { Suspense, lazy } from 'react'
import { Head } from 'features/head'

const BuyBack = lazy(() => import('features/buy-back'))

export const BuyBackPage: React.FC = () => {
  return (
    <>
      <Head title='Buyback' />
      <Suspense fallback={<>Loading..</>}>
        <BuyBack />
      </Suspense>
    </>
  )
}
