import { FC } from 'react'
import { Flex } from '@radix-ui/themes'
import { XyroPulseDot } from 'shared/components'
import { XyroLogoLink, XyroButton } from 'shared/ui'
import styles from '../sign-up.module.scss'

export const AuthTestnetBanner: FC = () => {
  return (
    <Flex
      direction={'column'}
      align='center'
      justify='center'
      gap='2'
      px='9'
      mb='9'
      width='100%'
      className={styles.authHeroButton}
    >
      <XyroLogoLink withText />
      <XyroButton
        color='cyan'
        variant='outline'
        size='4'
        isWide
      >
        MAINNET is Live
        <XyroPulseDot />
      </XyroButton>
    </Flex>
  )
}
