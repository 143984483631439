import { Head } from 'features/head'
import { OnboardingOneOvOne } from 'features/onboarding'

export const OnboradingModeOneVsOnePage: React.FC = () => {
  return (
    <>
      <Head title='Onborading' />
      <OnboardingOneOvOne />
    </>
  )
}
