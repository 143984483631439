import { Head } from 'features/head'
import { OnboardingRewardsSystem } from 'features/onboarding'

export const OnboradingModeRewardsSystemPage: React.FC = () => {
  return (
    <>
      <Head title='Onborading' />
      <OnboardingRewardsSystem />
    </>
  )
}
