import { Head } from 'features/head'
import { OnboardingTreasure } from 'features/onboarding'

export const OnboradingModeTreasurePage: React.FC = () => {
  return (
    <>
      <Head title='Onborading' />
      <OnboardingTreasure />
    </>
  )
}
