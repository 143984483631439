import { makeVar } from '@apollo/client'
import { GameType, Maybe } from '__generated__/graphql'
import { ChatRoomType } from '../types/chat'

export const IS_CHAT_OPENED_KEY = 'isChatOpened'

const isChatOpenedFromSessionStorage =
  sessionStorage.getItem(IS_CHAT_OPENED_KEY) === 'true'

export const isChatOpenedVar = makeVar<boolean>(isChatOpenedFromSessionStorage)
export const chatRoomIdVar = makeVar<string | null>(null)
export const activeChatRoomVar = makeVar<Maybe<ChatRoomType>>(null)
export const activeGameVar = makeVar<Maybe<GameType>>(null)
export const chatTitleVar = makeVar<string>('')
