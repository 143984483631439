import { SmartContractEntity } from '__generated__/graphql'
import { Web3Adress } from 'shared/types/web3'
import { mapContractName } from './map-contract-name'

const adressesUrl =
  import.meta.env.VITE_STAND === 'local' ?
    '../../public/contract-addresses.json'
  : '/contract-addresses.json'

const getAddressList: () => Promise<Record<string, Web3Adress>> = async () =>
  await fetch(adressesUrl)
    .then(data => data.json())
    .then(data => {
      const addressList = mapAddresses(data)
      console.info('🚀 ~ addressList:', addressList)
      return addressList
    })
    .catch(err => {
      console.error('🛑 Mapping error: ', err)
      console.info(
        'UnknownAddress: see #getAddressList at `src/contracts/address.ts`'
      )

      const unknownAddress = '<Unknown>' as Web3Adress
      return {
        bullsEye: unknownAddress,
        upDown: unknownAddress,
        oneVsOne: unknownAddress,
        setups: unknownAddress,
        setupsFactory: unknownAddress,
        oneVsOneExactPrice: unknownAddress,
        oneVsOneUpDown: unknownAddress,
        usdc: unknownAddress,
        treasury: unknownAddress,
        helper: unknownAddress
      }
    })

const getSmartContracts: () => Promise<SmartContractEntity[]> = async () =>
  await fetch(adressesUrl)
    .then(data => data.json())
    .then(data => data)
    .catch(err => {
      console.error('🛑 Mapping error: ', err)
      console.info(
        'UnknownAddress: see #getSmartContracts at `src/contracts/address.ts`'
      )

      return []
    })

const mapAddresses = (addresses: Record<string, Web3Adress>[]) => {
  const contractAddresses = addresses.reduce(
    (acc: Record<string, Web3Adress>, item: Record<string, Web3Adress>) => {
      const initName = item['contractName']
      const name = mapContractName(initName)

      const address = item['contractAddress']
      return { ...acc, [name]: address }
    },
    {} as Record<ContractAddressType, Web3Adress>
  )

  return contractAddresses
}

export const addressList = await getAddressList()

export const smartContracts = await getSmartContracts()

type ContractAddressType =
  | 'upDown'
  | 'oneVsOneUpDown'
  | 'oneVsOneExactPrice'
  | 'setups'
  | 'setupsFactory'
  | 'usdc'
  | 'treasury'
  | 'helper'
