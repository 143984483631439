import { Head } from 'features/head'
import { OnboardingReferralProgram } from 'features/onboarding'

export const OnboradingModeReferralProgramPage: React.FC = () => {
  return (
    <>
      <Head title='Onborading' />
      <OnboardingReferralProgram />
    </>
  )
}
