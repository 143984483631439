import { useReactiveVar } from '@apollo/client'
import { Box, Flex, Text } from '@radix-ui/themes'
import { DataTestIDs } from 'shared/constants'
import { balanceVar } from 'shared/store/balance-store'
import { TetherToken } from 'shared/ui'
import { formatBalance } from '../format-balance'
import styles from '../approved-balance.module.scss'

export const ApprovedBalanceAmount = () => {
  const balance = useReactiveVar(balanceVar)

  const fullFormattedBalance = formatBalance(
    balance.treasuryDeposit + balance.usdtBalance
  )
  const walletFormattedBalance = formatBalance(balance.usdtBalance)
  const treasuryFormattedBalance = formatBalance(balance.treasuryDeposit)
  const formattedAllowance = formatBalance(balance.treasuryAllowance)

  return (
    <Box
      className={styles.approvedAmountBox}
      mt={'6'}
    >
      <Flex
        direction={'column'}
        className={styles.approvedAmountContent}
      >
        <Text
          size={'1'}
          className={styles.approvedAmountTitle}
          weight={'bold'}
        >
          Total balance:
        </Text>

        <Flex align={'center'}>
          <Flex
            justify={'center'}
            className={styles.approvedAmountTokenIcon}
          >
            <TetherToken size='5rem' />
          </Flex>
          <Text
            size={'8'}
            data-testid={DataTestIDs.treasureTotalValue}
          >
            {fullFormattedBalance}
          </Text>
        </Flex>

        <Flex
          gap={'2'}
          mt={'4'}
        >
          <Flex className={styles.balanceWrap}>
            <Flex
              direction={'column'}
              p={'3'}
              gap={'2'}
            >
              <Text
                size={'1'}
                weight={'bold'}
                color={'gray'}
                className={styles.balanceTitle}
              >
                WALLET BALANCE
              </Text>
              <Flex>
                <Flex className={styles.approvedAmountTokenIcon}>
                  <TetherToken size={'2.5rem'} />
                </Flex>
                <Text data-testid={DataTestIDs.treasureWalletValue}>
                  {walletFormattedBalance}
                </Text>
              </Flex>
            </Flex>

            <Box className={styles.approvedDivider} />

            <Flex
              direction={'column'}
              p={'3'}
              gap={'2'}
            >
              <Text
                size={'1'}
                weight={'bold'}
                color={'gray'}
                className={styles.balanceTitle}
              >
                APPROVED
              </Text>
              <Flex>
                <Flex className={styles.approvedAmountTokenIcon}>
                  <TetherToken size={'2.5rem'} />
                </Flex>
                <Text data-testid={DataTestIDs.treasureApprovedValue}>
                  {formattedAllowance}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            direction={'column'}
            className={styles.balanceWrap}
            gap={'2'}
            p={'3'}
          >
            <Text
              size={'1'}
              weight={'bold'}
              color={'gray'}
              className={styles.balanceTitle}
            >
              TREASURE BALANCE
            </Text>
            <Flex>
              <Flex className={styles.approvedAmountTokenIcon}>
                <TetherToken size={'2.5rem'} />
              </Flex>
              <Text data-testid={DataTestIDs.treasureTreasureValue}>
                {treasuryFormattedBalance}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
