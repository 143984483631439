import { ChangeEvent, FC, useCallback } from 'react'
import { Button, Flex, Text } from '@radix-ui/themes'
import { ControllableAmountField, TetherToken } from 'shared/components'
import { DataTestIDs, PRICE_PATTERN } from 'shared/constants'
import styles from '../approved-balance.module.scss'

interface Props {
  value: string
  setValue: (value: string) => void
  maxAmount: number
  onChange: (value: string) => void
  onlyInteger?: boolean
}

export const ApprovedBalanceAmountField: FC<Props> = ({
  value,
  setValue,
  maxAmount,
  onlyInteger,
  onChange
}) => {
  const onClickMax = useCallback(() => {
    setValue(String(maxAmount))
    onChange(String(maxAmount))
  }, [maxAmount, setValue, onChange])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return

      onChange(event.target.value)
    },
    [onChange]
  )

  const pattern = !onlyInteger ? PRICE_PATTERN : undefined
  const inputMode = !onlyInteger ? 'decimal' : 'numeric'

  return (
    <Flex
      className={styles.approvedFormAmount}
      position={'relative'}
    >
      <ControllableAmountField
        name={'amount'}
        value={value}
        setValue={setValue}
        onChange={handleChange}
        minAmount={0}
        max={maxAmount}
        onlyInteger={onlyInteger}
        pattern={pattern}
        inputMode={inputMode}
        required
        dataTestID={DataTestIDs.treasureInput}
      />

      <Flex className={styles.amountFieldXyroIcon}>
        <TetherToken size='3rem' />
      </Flex>

      <Button
        className={styles.amountFieldMaxButton}
        variant={'ghost'}
        type={'button'}
        onClick={onClickMax}
        data-testid={DataTestIDs.buttonTreasureMax}
      >
        <Text color={'yellow'}>MAX</Text>
      </Button>
    </Flex>
  )
}
