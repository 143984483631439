import { Head } from 'features/head'
import { OnboardingSetups } from 'features/onboarding'

export const OnboradingModeSetupsPage: React.FC = () => {
  return (
    <>
      <Head title='Onborading' />
      <OnboardingSetups />
    </>
  )
}
