import { useCallback, useEffect, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import * as RadixForm from '@radix-ui/react-form'
import { Box, Button, Flex, Text } from '@radix-ui/themes'
import cn from 'classnames'
import { DataTestIDs } from 'shared/constants'
import { balanceVar } from 'shared/store/balance-store'
import { BetsTypeSwitcher, XyroLoading } from 'shared/ui'
import { BALANCE_CLAIM_SWITCH_TYPES } from '../constants'
import { useApproveBalanceFormSubmit } from '../hooks/use-approved-balance-form-submit'
import { activeBalanceSwitchTypeVar } from '../store/switch-types'
import { ApprovedBalanceAmountField } from './approved-balance-amount-field'
import styles from '../approved-balance.module.scss'

/* eslint-disable-next-line max-statements */
export const ApprovedBalanceForm = () => {
  const [isDisabledButton, setButtonDisabled] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const formRef = useRef<HTMLFormElement>(null)

  const activeType = useReactiveVar(activeBalanceSwitchTypeVar)
  const balance = useReactiveVar(balanceVar)

  const isApprove = activeType === BALANCE_CLAIM_SWITCH_TYPES.Approve

  const { handleSubmit, loading } = useApproveBalanceFormSubmit()

  const handleChangeAmountValue = useCallback(
    (value: string) => setButtonDisabled(!value),
    [setButtonDisabled]
  )

  useEffect(() => {
    setInputValue('')
  }, [isApprove])

  const formattedBalance = Number(balance.usdtBalance.toFixed(2))
  const formattedTreasury = Number(balance.treasuryDeposit.toFixed(2))

  const maxAmount = isApprove ? formattedBalance : Math.floor(formattedTreasury)

  const fieldTitle = isApprove ? 'WALLET BALANCE' : 'AVAILABLE TO CLAIM'

  const balanceValue = `${isApprove ? formattedBalance : formattedTreasury} USDT`

  const buttonTitle = isApprove ? 'APPROVE' : 'CLAIM'

  useEffect(() => {
    formRef.current?.reset()
  }, [isApprove])

  return (
    <Box mt={'6'}>
      <Flex mb={'6'}>
        <BetsTypeSwitcher
          activeType={activeType}
          setActiveType={activeBalanceSwitchTypeVar}
          betsTypes={BALANCE_CLAIM_SWITCH_TYPES}
          size='4'
          betsTypeDataTestIDs={[
            buttonTreasureApproveBalance,
            buttonTreasureClaim
          ]}
        />
      </Flex>

      <RadixForm.Root
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <Flex
          direction={'column'}
          align={'center'}
        >
          <Flex
            className={styles.approvedFormAmountLabel}
            gap={'1'}
          >
            <Text
              size={'1'}
              weight={'bold'}
              color='gray'
            >
              {fieldTitle}:
            </Text>

            <Text
              size={'1'}
              weight={'bold'}
              className={'color-white'}
              data-testid={treasureAvailableAmount}
            >
              {balanceValue}
            </Text>
          </Flex>

          <ApprovedBalanceAmountField
            value={inputValue}
            setValue={setInputValue}
            onChange={handleChangeAmountValue}
            maxAmount={maxAmount}
            onlyInteger={!isApprove}
          />

          <Button
            className={cn(styles.approvedFormButton, {
              [styles.approvedFormButtonDisabled]: isDisabledButton || loading
            })}
            disabled={isDisabledButton || loading}
            variant={loading ? 'outline' : 'classic'}
            type={'submit'}
            size={'4'}
            mt={'6'}
            data-testid={buttonTreasureAction}
          >
            <XyroLoading loading={loading}>
              <Text
                size={'2'}
                weight={'bold'}
              >
                {buttonTitle}
              </Text>
            </XyroLoading>
          </Button>
        </Flex>
      </RadixForm.Root>
    </Box>
  )
}

const {
  buttonTreasureApproveBalance,
  buttonTreasureClaim,
  treasureAvailableAmount,
  buttonTreasureAction
} = DataTestIDs
