import { useReactiveVar } from '@apollo/client'
import { Flex, Text } from '@radix-ui/themes'
import { User } from '__generated__/graphql'
import { Link } from 'react-router-dom'
import { DataTestIDs, RouterPathes } from 'shared/constants'
import { CupIcon } from 'shared/icons'
import { userVar } from 'shared/store/user'
import { Maybe } from 'shared/types'
import { XyroButton, XyroToken } from 'shared/ui'
import { formatToXyro } from 'shared/utils/format-price'
import { useRewardsBalance } from '../hooks/use-rewards-balance'

export const RewardsBalance = () => {
  const user = useReactiveVar(userVar)
  const { userRewards, loading } = useRewardsBalance(user as Maybe<User>)

  const balanceFormat =
    loading ? '---' : formatToXyro(userRewards?.rewards || 0, 'long')

  if (!user) return null

  return (
    <Flex
      align={'center'}
      gap={{ initial: '2', xs: '3' }}
      direction={'row'}
    >
      <Link to={RouterPathes.rewards}>
        <XyroButton
          isIconOnly
          shape='cutted-both'
          color='pink'
          data-testid={DataTestIDs.buttonRewardsHeader}
        >
          <CupIcon
            width={'2.5rem'}
            height={'2.5rem'}
            color='var(--white)'
          />
        </XyroButton>
      </Link>

      <Flex direction={'column'}>
        <Text
          size={'1'}
          className={'color-white'}
          weight={'medium'}
        >
          Rewards
        </Text>

        <Flex
          align={'center'}
          gap={'1'}
        >
          <XyroToken
            size='2.5rem'
            color='yellow'
          />

          <Text
            className='color-white'
            data-testid={DataTestIDs.headerRewardAmount}
          >
            {balanceFormat}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
